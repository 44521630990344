@import "src/assets/fonts/fonts";

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body{
  font-family: 'NexaLg', sans-serif;
  font-size: 14px;
}

b, strong{
  font-family: 'NexaBd', sans-serif;
  font-weight: 600;
}

.font-bold {
  font-family: $font-mentita-bold, sans-serif;
  font-weight: 600;
}

.font-light {
  font-family: $font-mentita-light, sans-serif;
  font-weight: lighter;
}

p {
  font-family: NexaLg, sans-serif !important;
}

h1 {
  font-family: NexaBd, sans-serif !important;
}

h2 {
  font-family: NexaBd, sans-serif !important;
}

h3 {
  font-family: NexaBd, sans-serif !important;
}

h4 {
  font-family: NexaBd, sans-serif !important;
}

h5 {
  font-family: NexaBd, sans-serif !important;
}

h6 {
  font-family: NexaBd, sans-serif !important;
}

span {
  font-family: NexaLg, sans-serif;
}

.magenta-color {
  color: $magenta;
}

.mentita-color {
  color: $mentita-blue;
}

.pink-color {
  color: $pink;
}

.text-underline {
  &:hover {
    text-decoration: underline !important;
  }
}

.btn-mentita {
  background-color: $mentita-blue;
  cursor: pointer;
}

.btn-mentita-danger {
  background-color: $pink;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.close-icon {
  font-size: 30px;
}

.center-text{
  display: flex;
  align-content: center;
}


@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-15px);
	}
	100% {
		transform: translatey(0px);
	}
}

.App{

}
