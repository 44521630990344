.UsersAdmin {
    padding: 1%;
    .name-input {
        width: 200px;
    }

    @media screen and (max-width: 768px){
        .responsive-filters{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }   
    }

    .balance-green{
        color: #1BC5BD;
    }
}