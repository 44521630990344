@import "src/assets/fonts/fonts";
@import "../../../assets/styles/colors";
@import "src/assets/styles/_breakpoints";
@import "src/assets/styles/_mixins";


.Landing {
  .main-logo {
    height: 85px;
  }

  .gradient {
    height: calc(100vh - 58px);
    width: 100vw;
    position: absolute;
    z-index: 5;

    @media only screen and (max-width: 900px) {
      background: none;
    }
  }

  .element-60 {
    width: 60%;
  }

  .element-50 {
    width: 50%;
  }

  .element-40 {
    width: 40%;
  }

  .element-30 {
    width: 3%;
  }

  min-height: calc(100vh - 58px);
  width: 100vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media screen and (max-width: 500px) {
    background-color: #9C7AFF;
  }

  .portada {
    height: 150%;
    padding: 1em 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;

    .nav {
      height: 20%;
      display: flex;
      justify-content: space-between;

      .nav-element {
        padding: 1em;
        width: 200px;

        @media screen and (max-width: 1200px) {
          padding: 0.5em;
          width: 150px;
        }

        @media screen and (max-width: 900px) {
          padding: 0.5em;
          width: 100px;
        }

        @media screen and (max-width: 500px) {
          padding: 0.5em;
          width: 80%;
        }
      }

      .logo-box {
        margin-left: 2em;
        align-self: center;
      }

      .nav-elements-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 530px) {
          margin: 0 auto;
          justify-content: space-around;
        }
      }

      .login-box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .thinking-icon {
          width: 70px;
        }

        p {
          color: #873597;
          font-size: 1.6rem;
          font-family: 'NexaBd' !important;

          @media only screen and (max-width: 1200px) {
            font-size: 1.6rem;
          }

          @media only screen and (max-width: 900px) {
            font-size: 1.25rem;
          }
        }
      }

      .login-box-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        animation: float 3s ease-in-out infinite;


        img {
          width: 50%;

          @media screen and (max-width: 500px) {
            width: 30%;
          }
        }

        p {
          color: #873597;
          font-size: 1.25rem;
          font-weight: bolder;
        }
      }
    }

    .register-box {
      background-color: rgba(215, 185, 238, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;

      @media screen and (max-width: 550px) {
        width: 100%;
      }

      p {
        color: #52297C;



      }
    }
  }

  p:hover {
    color: #7d6694 !important;
  }

  .body {

    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;

    @media only screen and (min-width: 900px) and (max-width: 1370px) and (max-height: 880px) {}

    @media only screen and (max-width: 1200px) {}

    @media only screen and (max-width: 400px) {
      text-align: center;
    }

    .slide-link {
      background-color: $magenta;
      color: white;
      display: flex;
      justify-content: space-around;
      position: absolute;
      right: -165px;
      padding: 10px;
      align-items: center;

      &.body-privacy {
        top: -150px;
      }

      &.body-return {
        top: -90px;
        right: -180px;
      }

      @media screen and (max-width: 500px) {
        top: -100px;
      }

      &:hover {
        right: 0px;
        transition: 1s;
      }

      .icon {
        font-size: 30px;
        margin-right: 10px;
      }

      a {
        color: white;
        font-family: NexaBd, sans-serif !important;
        text-align: center;
        margin-left: 5px;
      }
    }



    &-container {
      &_text {
        font-family: 'Nexa-Bold', sans-serif;
        font-size: 3.25rem;
        line-height: 4rem;
        color: white;



        @media only screen and (max-width: 1200px) {
          font-size: 2.25rem;
          line-height: 2.5rem;
        }

        @media only screen and (max-width: 1200px) {
          font-size: 1.75rem;
          line-height: 2rem;
        }
      }

      &_button {
        font-size: 2rem;
        color: white;
        background-color: #873597;
        text-align: center;
        width: 50%;
        margin: 0 auto;
        padding: 1rem 1.5rem;
        border-radius: 32px;

        @media only screen and (max-width: 1200px) {
          width: 70%;
          font-size: 1.5rem;
        }
      }
    }

    &-social-container {
      margin: 0 auto;
      font-size: 4rem;
      padding-bottom: 50px;
      display: table-caption;

      @media only screen and (max-width: 1440px) {
        font-size: 100px;
        padding-bottom: 1.5em;

        @media only screen and (max-width: 400px) {
          position: initial;
          display: inline;
        }

      }

      .wp {
        width: 3.5%;
        position: absolute;
        display: flex;
        right: .5%;
        animation: float 6s ease-in-out infinite;

        @media only screen and (max-width: 420px) {
          width: 10%;
          display: inline-table;
          right: auto;
          margin: -5%;
        }


      }


      a {
        color: white;
        font-size: 2rem;
        padding: 2rem;

        @media only screen and (max-width: 1200px) {
          font-size: 2.5rem;

          @media screen and (max-width: 550px) {
            padding: 2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .links {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: rgba(215, 185, 238, 0.5);
      margin-bottom: 5px;
    }

    .login-link {
      padding-top: 10px;
    }
  }

}