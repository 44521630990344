@import "../../../../../assets/styles/colors";

.Loans {
    background-color: white;
    width: 95%;
    @media screen and (min-width: 700px) and (max-width: 899px) {
        max-width: 55%;
    }
    @media screen and (min-width: 900px) and (max-width: 1100px) {
        max-width: 65%;
    }
    @media screen and (min-width: 300px) and (max-width: 700px) {
        max-width: 90%;
    }
    margin: 2rem;
    border-radius: 15px;
    .TideReactTable {
        max-width: 100%;
        margin: 0 auto;
    }
    .button-add {
        background-color: $cyan;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-size: 30px;
        border: none;
        color: white;
    }
}