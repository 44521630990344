.CategoryLabel {
    position: absolute;
    margin: 0.5rem;
    right: 0;
    z-index: 4;
    background-color: #17a2b8;
    padding: 0.1rem 0.5rem;
    border-radius: 0.5rem;
    color: white;
    font-size: 14px;
}