.ShippingZoneAdmin {
  button {
    display: inline-block;
    font-weight: normal;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    padding: 5px;
  }
}