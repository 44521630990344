.Orders {
    width: 100%;
    padding: 1%;
    .address-text {
        white-space: nowrap; 
        width: 80px; 
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            overflow: auto;
            white-space: normal;
            width: 100px;
        }    
    }
    .status-container {
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        max-width: 150px;
        background-color: rgba(255, 200, 201, 0.3);
    }
    thead {
        display: table-header-group;
    }

    .total-container{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .payment-method-container{
        width: 40px;
        height: 40px;

        & > img{
            width: 100%;
            height: auto;
        }
    }
}