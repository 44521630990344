.KueskiPayment{
    color: #054eab;

    &__container{
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
        text-align: center;
    }

    &__subTitle{
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    &__stepsContainer{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        text-align: left;
        margin-top: 10px;
    }

    &__infoContainer{
        display: inline-block;
        width: 50%;
        vertical-align: top;
        text-align: left;
        margin-top: 10px;
    }

    &__steps{
        width: 100%;
        max-width: 420px;
        margin: 0 auto;
    }

    &__step {
        margin-top: 10px;
        height: 45px;
        display: flex;

        & div {
            border-radius: 30px;
            padding: 0;
            display: inline-block;
            background: #ff8900;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
            margin-right: 12px;
            height: 24px;
            width: 24px;
            text-align: center;
            padding-top: 3px;
        }

        & span {
            flex: 1;
        }
    }

    &__messageRedirect{
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__disclamer{
        font-size: 14px;
        font-weight: normal;
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: right;
        font-style: italic;
    }

    .loader-div,
    .loader-div:after {
        border-radius: 50%;
        width: 1em;
        height: 1em;
        float: left;
    }
    .loader-div {
        margin: 0 auto;
        font-size: 10px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
        margin-right: 14px;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}