$font-size: 16px;

$border-radius: 4px;
$input-border-radius: 7px;
$question-border-radius: 20px;
$border-radius-small: 2px;
$register-width-component: 700px;
$border-radius-large: 7px;
$menu-width: 200px;
$menu-hidden-width: 80px;

$nav-bar-height: 62px;