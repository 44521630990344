.SuperDashboard {
  margin: 2rem auto 0;
  width: 95%;

  .height-30 {
    height: 30rem;
  }

  .height-20 {
    height: 20rem;
  }
}