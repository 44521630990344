@import "../../../assets/styles/colors";

.BannerModal {
    width: 90%;
    background-color: white;
    height: 500px;
    margin: 2rem auto 0;
    border-radius: 10px;
    min-height: 90vh;
    .BannerModal-header {
        width: 90%;
        margin: 0 auto;
        border-bottom: 1px dashed grey;
        .header-title {
            padding: 2rem 0 1rem;
            text-align: center;
        }
    }
    .BannerModal-image {
        width: 80%;
        height: 50vh;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
    }
}