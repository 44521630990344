@import "../../assets/styles/measures.scss";

.DashLayout{
    background-color: #f3f3f3;
    min-height: 100vh;
    @media screen and (min-width: 700px) {
      .header-mobile {
        display: none;
      }
    }
    .logo-icon {
      width: 70px;
      margin-top: 15px;
    }
    @media screen and (min-width: 700px) {
      .ResponsiveMenu {
        display: none;
      }
      .responsive-burguer {
        position: fixed;
      }
    }
    .main-content{
      width: calc(100% - #{$menu-width});
      float: right;
      min-height: 100vh;
      transition: 1s ease-in-out;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    .hidden {
      width: calc(100% - #{$menu-hidden-width});
      float: right;
      min-height: 100vh;
      transition: 1s ease-in-out;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }
    .dash-screen{
      padding: 2em 2em 2em;
    }
    @media screen and (max-width: 700px) {
      .responsive-burguer {
        position: fixed;
        right: 30px;
        zoom: 150%;
      }
    }
  }
  