@import "../../../assets/styles/colors";

.ResetPassword{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../../assets/img/public/register-process/background-register.jpg");
    min-height: 100vh;
    .login-form {
        width: 100%;
        max-width: 450px;
    }
    .submit-button {
        background-color: $mentita-blue;
        border-radius: .675rem;
        color: white;
        padding: 0.7em 2em !important;

    }
    .icon {
        width: 200px;
        margin: 0 auto;
    }

    .login-signin {
        h3 {
            font-size: calc(1.275rem + .3vw);;
        }
        .text-muted {
            color: #b5b5c3!important;
        }
    }
    
    .login.login-4 .login-form {
        width: 100%;
        max-width: 450px
    }

    .form-control {
        background-color: #f3f6f9;
        height: 50px !important;
        width: 90% !important;
        margin: 0 auto;
        border-color: #f3f6f9;
        color: #3f4254;
        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
    }
    
    @media (max-width:575.98px) {
        .login.login-4 .login-form {
            width: 100%;
            max-width: 100%
        }
    }
}