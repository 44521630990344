@import "src/assets/styles/_breakpoints";
@import "src/assets/styles/_mixins";
@import "src/assets/styles/measures";

.Modal{
  position: fixed;
  background-color: rgba(0,0,0,0.7);
  width: 100vw;
  height: 100vh;
  z-index: 300;
  display: flex;
  align-items: center;
  justify-content: center;

  @include md {
    padding: 50px;
  }

  .inner-modal {
    max-height: 95vh;
    background-color: white;
    border-radius: 15px;
    position: relative;
    padding: 24px;
    width: 95vw;
    overflow-y: auto;
    @media screen and (min-width: 1200px){
      width: 70vw;
    }
    @media screen and (min-width: 1501px) {
      width: 50vw;
    }
    .title{
      text-align: center;
      margin: 30px 0;
    }
    .close-btn{
      position: absolute;
      right: 24px;
      top: 18px;
      background: none;
      border: none;
    }
  }
}
