@font-face {
    font-family: NexaBd;
    src: url("./Nexa/Nexa-Bold.otf");
}
@font-face {
    font-family: NexaLg;
    src: url(./Nexa/Nexa-Light.otf);
}
@font-face {
    font-family: 'Montserrat-medium';
    src: url("./Montserrat/Montserrat-Regular.ttf");
}
@font-face {
    font-family: 'Montserrat-semibold';
    src: url("./Montserrat/Montserrat-SemiBold.ttf");
}
