@import "colors";
@import "global";
@import "mixins";
@import "breakpoints";
@import "../fonts/fonts-def";

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }

  /* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: $pink;
    border-radius: 20px;
}
.font-bold {
    font-family: NexaBd, sans-serif !important;
    font-weight: 600;
}
.title-text {
    font-size: 1.1rem;
}
.logo-order {
    width: 60px;
}
.logo-product {
    width: 100px;
}
.text-small {
    font-size: 10px;
}
.text-medium {
    font-size: 12px;
}

.magenta-color {
    color: $magenta;
}

.scroll-down-block {
    max-height: 90vh !important;
    overflow-y: hidden !important;
}
.btn-clear{
    border: none;
    background: none;
}
