@import "src/assets/styles/colors";
@import "src/assets/fonts/fonts";

.TotalSells {
    .card {
        min-height: 250px;
    }
    .font-bold {
        font-family: NexaBd, sans-serif !important;
        font-weight: 600;
    }
    .title-text {
        font-size: 1.1rem;
    }
    thead {
        display: none;
    }
    .logo-order {
        width: 60px;
    }
    .logo-product {
        width: 100px;
    }
    .text-small {
        font-size: 10px;
    }
    .text-medium {
        font-size: 12px;
    }
}