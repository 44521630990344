@import "src/assets/styles/colors";


.TideReactTable-Pagination{
  text-align: right;

  @media screen and (max-width: 700px) {
    text-align: center;
    margin: 0 auto;
  }

  .page-size-block{
    display: inline-block;
    .page-select{
      border-radius: 5px;
      border: 1px solid lightgrey;
      background: white;
      padding: 5px 10px;
      margin: 0 10px;
    }
  }
  .actual-rows{
    display: inline-block;
  }
  .pagination-btn{
    display: inline-block;
    min-width: 2.2rem;
    min-height: 2.2rem;
    margin: 0 0.2rem;
    border: none;
    cursor: pointer;
    background: none;
    &.pag-ctrl{
      font-size: 1.4em;
    }
    &.active{
      color: white;
    }
  }
}
