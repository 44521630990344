@import "src/assets/fonts/fonts";
@import "src/assets/styles/colors";

.ShoppingDetails-button {
  color: white;
  text-align: center;
  position: relative;
  .cart-button {
    width: 100%;
    padding-top: 12px;
    background-color: #00c2d6;
    color: white;
    font-family: NexaBd, sans-serif;
  }
  .hidden-icon {
    font-size: 22px !important;
    color: #007bff !important;
  }
  .count-badge {
    background-color: #39b14a;
    border-radius: 16px;
    position: absolute;
    right: -8px;
    top: -18px;
    font-family: $font-mentita-bold;
    text-align: center;
    padding: 4px 8px;
    min-width: 32px;
    display: block;
  }
}
.continue {
  font-family: NexaBd, sans-serif !important;
}

.CartModal {
  .heading {
    padding: 1em 0;
    border-bottom: 1px solid #d0d0d0;

    h1 {
      font-size: 2em;
      float: left;
    }
  }

  .cart {
    padding: 1em 0;
    max-height: 50vh;
    overflow-y: auto;
    .cartWrap {
      list-style: none;

      .item {
        &:first-of-type {
          margin-top: 1em;
        }
        .cartSection {
          .image-container {
            height: 70px;
            width: 100px;
            .image {
              height: 100%;
              width: 100%;
              border-radius: 20px;
              margin: 0 auto;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }

          .itemNumber {
            font-size: 0.75em;
            color: #777;
            margin-bottom: 0.5em;
          }

          h3 {
            font-size: 1em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.025em;
          }

          p {
            font-size: 0.85em;
            color: #777777;
            .quantity {
              font-weight: bold;
              color: #333;
            }

            input.qty {
              width: 4em;
              text-align: center;
              font-size: 1em;
              padding: 0.25em;
              margin: 1em 0.5em 0 0;
            }

            &.stockStatus {
              color: #82ca9c;
              font-weight: bold;
              padding: 0.5em 0 0 1em;
              text-transform: uppercase;
              &.out {
                color: #f69679;
              }
            }
          }
        }
        .remove {
          font-size: 20px;
          color: $pink;
          cursor: pointer;
        }
      }
    }
  }
  .points-balance {
    text-align: right;
    font-size: 2em;
    color: #e4a3c2;
  }

  .subtotal {
    float: right;
    width: 35%;
    .totalRow {
      padding: 0.5em;
      text-align: right;
      &.final {
        font-size: 25px !important;
        font-weight: bold;
      }
      span {
        display: inline-block;
        padding: 0 0 0 1em;
        text-align: right;
      }
      .label {
        font-size: 15px !important;
        text-transform: uppercase;
        color: #777;
      }
      .value {
        letter-spacing: -0.025em;
        width: 35%;
      }
    }
  }

  @media only screen and (max-width: 50em) {
    .subtotal {
      width: 100%;
    }
  }

  @media only screen and (max-width: 39.375em) {
    .prodTotal,
    .removeWrap {
      display: none;
    }

    .cart {
      .cartWrap {
        list-style: none;

        .item {
          .cartSection {
            p {
              font-size: 0.6em;
              color: #777777;
              .quantity {
                font-weight: bold;
                color: #333;
              }
            }
          }
        }
      }
    }

    .points-balance {
      text-align: right;
      font-size: 1.5em;
      color: #e4a3c2;
    }
  }
}
