.Accounting {
  .pay-container {
    @media screen and (min-width: 300px) and (max-width: 700px) {
      width: 95%;
    }
    @media screen and (min-width: 701px) {
      max-width: calc(100% - 250px);
    }
  }
    .DateInput_input {
        font-size: 17px;
        padding: 8px 8px 6px;
        cursor: pointer;
      }
      .DateRangePickerInput__withBorder {
        border-radius: 10px;
      }
}