.OrderDetails {
    font-family: 'NexaLg';

    @media screen and (min-width: 600px) {
        padding: 5em;
    }
    padding: 1rem;
    .detailsContainer {
        position: relative;
        top: 6px;
    }
    .OrderDetails-card {
        background-color: white;
        border-radius: 15px;
        padding: 3rem;
        width: 100%;
        .header-row {
            position: relative;
            width: 100%;
            .close-icon {
                font-size: 20px;
                position: absolute;
                right: 0;
            }
            .OrderDetails-header {
                border-bottom: 1px solid grey;
                h1 {
                    color: grey;
                }
                h5 {
                    text-align: justify;
                }
            }
        }
        .OrderDetails-body {
            min-height: 50vh;
            max-height: 20vh;
            .image-container {
                width: 100px;
                height: 100px;
                border-radius: 10px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            .quantity-box {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.5rem 2rem;
                background: -webkit-linear-gradient(#fbfcfd, #ebeced);
                background: linear-gradient(#fbfcfd, #ebeced);
                -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            }
            .details-box {
                max-width: 300px;
            }
        }
        .OrderInfo-box {
            background: #fbfffd;
            border: 1px solid #ccc;
            border-top-color: #bbb;
            .OrderInfo-title {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                font-size: 1.25rem;
                padding: 0 0.5em;
                text-align: justify;
            }
            .OrderInfo-items {
                border-bottom: 2px dotted rgba(55, 155, 255, 0.15);
                display: block;
                line-height: 1;
                margin: 0.375em 0;
                padding-left: 0.5em;
                padding-right: 0.5em;
            }
        }
    }
    .font-bold {
        font-family: NexaBd, sans-serif !important;
        font-weight: 600;
    }
    .title-text {
        font-size: 1.1rem;
    }
    thead {
        display: none;
    }
    .logo-order {
        width: 60px;
    }
    .logo-product {
        width: 100px;
    }
    .text-small {
        font-size: 10px;
    }
    .text-medium {
        font-size: 12px;
    }
    .table-head {
        display: table-header-group;
    }
}

.btn-kueski{
    background-color: #008BDF !important;
    border-color: #008BDF !important;
}