@import "src/assets/fonts/fonts";

.RecentOrders {
    .card-body {
        max-height: 500px;
        overflow-y: auto;
    }
    .font-bold {
        font-family: NexaBd, sans-serif !important;
        font-weight: 600;
    }
    .title-text {
        font-size: 1.1rem;
    }
    thead {
        display: none;
    }
    .logo-order {
        width: 60px;
    }
    .logo-product {
        width: 100px;
    }
    .text-small {
        font-size: 10px;
    }
    .text-medium {
        font-size: 12px;
    }
    .status-container {
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
        background-color: #FFC8C9;
    }
}