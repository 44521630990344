.AsideBarCat {
    width: 315px;
    max-width: 3150px;
    background-color: white;
    height: 100vh;
    @media screen and (min-width: 300px) and (max-width: 700px) {
        width: 95%;
        max-width: 95%;
        margin: 1rem auto;
        height: auto;
    }
    .catalogue-icon {
        font-size: 15px;
    }
    .list {
        @media screen and (min-width: 300px) and (max-width: 700px) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
    }
}