.ReportWidget {
    .report-block{
        background-color: white;
        border: 1px solid #e5e5e5;
        padding: 10px;
        border-radius: 5px;
        .DateInput_input {
          font-size: 17px;
          padding: 8px 8px 6px;
        }
        .DateRangePickerInput__withBorder {
          border-radius: 10px;
        }
        .title{
          font-size: 1.2em;
          cursor: pointer;
          font-family: 'NexaBd' !important;
        }
        .filter-label{
          margin: 10px 0 4px 0;
          font-size: 1.1em;
        }
        .description{
          border: 1px solid #e5e5e5;
          padding: 10px;
          border-radius: 5px;
        }
        .caret-icon{
          color: #797979;
          float: right;
          transition: transform 0.3s;
        }
        &.open .caret-icon{
          transform: rotate(180deg);
        }
    }
}