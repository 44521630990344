.TideTableFilters {
    margin: 20px;
    .headerContainer {
        cursor: pointer;
        width: 250px;
        margin-bottom: 25px;
    }
    .inputsContainer {
        max-width: 95vw;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .inputContainer {
            margin: 10px;
        }
    }
}