.ProfileMenu {
    .text-hover-primary {
        word-wrap: break-word;
        max-width: 60%;
    }
    .user-icon {
        font-size: 70px;
    }

    .select-profile-img-container {
        display: flex;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: 15px;
        justify-content: space-between;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

        img {
            width: 45%;
        }
    }
}