.FieldEditor{
  display: flex;
  padding: 5px 0;
  .field-input{
    border-radius: 5px 0 0 5px;
  }
  .action-btn{
    background: none;
    padding: 4px;
    min-width: 28px;
    border: 1px solid #E4E6EF;
    &.confirm{
      color: #39B14A;
    }
    &.cancel{
      border-radius: 0 5px 5px 0;
      color: #E4A3C2;
    }
  }
}
