@import "src/assets/styles/colors";

.TideToaster{
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 500;

  .message-bar{
    width: 380px;
    max-width: 90vw;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px -2px black;
    background: white;
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
    .left-bar{
      width: 6px;
      border-radius: 5px;
      flex-shrink: 0;
      background: lightgray;
    }
    .toast-icon{
      width: 80px;
      align-self: center;
      flex-shrink: 0;
    }
    .toast-content{
      .toast-title{
        margin: 0 0 4px 0;
        font-size: 1.05em;
        font-weight: bold;
      }
      align-self: center;
    }
    &.error{
      .left-bar{
        background: #d22e2e;
      }
    }
    &.info{
    .left-bar{
      background: #4693ff;
    }
    }
    &.success {
      .left-bar{
        background: #4DBB18;
      }

    }
  }
}
