@import "src/assets/fonts/fonts";
.ShareModal{
  .share-content{
    display: flex;
    @media screen and (max-width: 700px){
      flex-direction: column;
    }
    > div{
      flex: 1;
    }
  }
  .description{
    text-align: center;
  }
  .code-title{
    font-size: 22px;
    margin-bottom: 0;
  }
  .code{
    font-size: 60px;
    font-family: $font-mentita-bold;
    margin-top: 0;
  }
  .share-btn{
    min-width: 240px;
  }
  .buttons-container{
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
  }
  .image {
    text-align: center;
    .aliadas-img {
      width: 80%;
      border-radius: 20px;
    }
  }

}
