.UsersAdminEdit {

    .SingleDatePicker {
        width: 100%;

        .SingleDatePickerInput__withBorder {
          border-radius: 0;
          border: none;
          width: 100%;

          .DateInput {
            width: 100%;
          }

          .DateInput_input {
            display: block;
            width: 100%;
            height: calc(1.5em + 1.3rem + 2px);
            padding: 0.65rem 1rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #3F4254;
            background-color: #ffffff;
            background-clip: padding-box;
            border: 1px solid #E4E6EF;
            border-radius: 0.42rem;
            box-shadow: none;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          }

          .date-button {
            padding: 5px 7px !important;
            option {
              padding: 25px 0 !important;
            }
          }

          .DateInput_input:focus {
            color: #3F4254;
            background-color: #ffffff;
            border-color: #30e3da;
            outline: 0;
          }
        }
      }
    .input-group-text {
        font-size: 13px;
        width:120px;
    }
    .inner-modal {
        @media screen and (min-width: 768px) {
            width: 50%;
            margin: 0 auto;
        }
        @media screen and (max-width: 767px) {
            width: 99%;
            margin: 30rem auto 0;
        }
        .input-label {
            width:120px;
            @media screen and (max-width: 1200px) {
                width: 70px;
            }
        }
    }

    .form {
        .card-body {
            #birthday-field {
                width: auto;
            }

            .SingleDatePicker {
                width: 85%;

                .SingleDatePickerInput__withBorder {
                    border-radius: 0;
                    border: none;
                    width: 100%;

                    .DateInput {
                        width: 100%;
                    }

                    .DateInput_input {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 1.3rem + 2px);
                        padding: 0.65rem 1rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #3F4254;
                        background-color: #ffffff;
                        background-clip: padding-box;
                        border: 1px solid #E4E6EF;
                        border-radius: 0.42rem;
                        box-shadow: none;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    }

                    .DateInput_input:focus {
                        color: #3F4254;
                        background-color: #ffffff;
                        border-color: #30e3da;
                        outline: 0;
                    }
                }
            }

            .form-control-selectpicker {
                width: 84%;
            }
        }
    }
}
