@import "src/assets/styles/_breakpoints";
@import "src/assets/styles/_mixins";

.Footer {
    background-color: #53297b;
    width: 100%;
    color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 0;
    padding-right: 20px;
    color: white;
    flex-direction: column;

    padding-top: 5px;
    padding-bottom: 5px;

    .separator {
        display: none;

        @include md {
            display: contents;
        }
    }

    > div {
        color: white;
    }

    & > a {
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @include md {
        flex-direction: row;
        gap: 30px;
        height: 60px;
    }
}