@import "src/assets/styles/colors";
.ProfileEdit {
    max-height: 60vh !important;
    @media screen and (max-width: 1500px) and (max-height: 850px) {
        overflow: auto;
        padding-right: 10px;
        &::-webkit-scrollbar {
            width: 3px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #dbc7b1;
            border-radius: 100px;
        }
    }
    .addressStart {
        border-top: 1px dashed $pink;
        padding-top: 20px;
    }
    .date-button {
        padding: 5px 7px !important;
        option {
            padding: 25px 0 !important;
        }
    }
    .addressEnd {
        border-bottom: 1px dashed $pink;
        padding-top: 20px;
    }
    .mb-2 {
        .SingleDatePicker {
            width: 100%;
            .SingleDatePickerInput__withBorder {
                border-radius: 0;
                border: none;
                width: 100%;
                .DateInput {
                    width: 100%;
                }
                .DateInput_input {
                    display: block;
                    width: 100%;
                    height: calc(1.5em + 1.3rem + 2px);
                    padding: 0.65rem 1rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: #3F4254;
                    background-color: #ffffff;
                    background-clip: padding-box;
                    border: 1px solid #E4E6EF;
                    border-radius: 0.42rem;
                    box-shadow: none;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
                .DateInput_input:focus {
                    color: #3F4254;
                    background-color: #ffffff;
                    border-color: #30e3da;
                    outline: 0;
                }
            }
        }
    }
    @media screen and (min-width: 992px) {
        .SingleDatePicker_picker {
            left: -165px !important;
        }
    }
}