.ExpensesForm {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 80px;
    .SingleDatePicker {
        width: 100%;
  
        .SingleDatePickerInput__withBorder {
            border-radius: 0;
            border: none;
            width: 100%;
  
            .DateInput {
              width: 100%;
            }
  
            .DateInput_input {
              display: block;
              width: 100%;
              height: calc(1.5em + 1.3rem + 2px);
              padding: 0.65rem 1rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: #3F4254;
              background-color: #ffffff;
              background-clip: padding-box;
              border: 1px solid #E4E6EF;
              border-radius: 0.42rem;
              box-shadow: none;
              transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
  
            .DateInput_input:focus {
              color: #3F4254;
              background-color: #ffffff;
              border-color: #30e3da;
              outline: 0;
            }
        }
    }
    .card-custom {
        max-height: 95vh;
        overflow-y: auto;
        @media screen and (min-width: 300px) and (max-width: 500px) {
            min-width: 95vw;
        }
        @media screen and (min-width: 501px) and (max-width: 767px) {
            min-width: 85vw;
        }
        @media screen and (min-width: 768px) and (max-width: 1200px) {
            min-width: 95vw;
        }
        @media screen and (min-width: 1201px) and (max-width: 1500px) {
            min-width: 70vw;
        }
        @media screen and (min-width: 1501px) {
            min-width: 50vw;
        }
    }
}