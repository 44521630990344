.PricesModal {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 80px;
    @media screen and (max-width: 650px) {
        padding: 20px;
    }
    .inner-modal {
        padding: 20px;
        width: 55%;
        margin: 0 auto;
        @media screen and (max-width: 650px) {
            width: 95%;
        }
    }
}