.Register {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-image: url("../../../assets/img/public/register-process/background-register.jpg");
    min-height: 100vh;
    .login-form {
        width: 100%;
        max-width: 1000px;
    }
    .registerResponse {
        width: 100%;
        height: 300px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .submit-button {
        background-color: #873597;
        border-radius: .675rem;
        color: white;
        padding: 0.7em 2em !important;
        font-family: 'NexaBd';

    }
    .terms-text {
        color: #1BC5BD;
        text-decoration: none;
        background-color: transparent;
    }

    .form-container{
        display: flex;
        @media screen and (max-width: 1100px){
            flex-direction: column;
        }
        .form-column{
            flex: 1;
        }
    }

    .form-group {
        /* Checkbox-label and icon when focus */
        margin-left: 25px;
        .checkbox > input {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }
        label {
            font-size: 1rem;
            font-weight: 400;
            color: #3F4254;
        }
        a {
            color: #1BC5BD;
            text-decoration: none;
            background-color: transparent;
        }
        .checkbox {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            text-align: left;
            cursor: pointer;
            font-size: 1rem;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            margin: 0;
        }
        input[type=radio], input[type=checkbox] {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0;
        }
        .checkbox-inline .checkbox span {
            margin-right: 0.75rem;
        }
        .checkbox-inline {
            margin-left: 1.5rem;
        }
        .checkbox > span {
            background-color: #EBEDF3;
            border: 1px solid transparent;
        }
        .checkbox > span {
            height: 18px;
            width: 18px;
        }
        .checkbox > span {
            background-color: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            border-radius: 0.42rem;
        }
        .checkbox:hover > input:not([disabled]) ~ span, .checkbox > input:focus ~ span {
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        .checkbox > input:checked ~ span {
            background-color: #1BC5BD;
        }
        .checkbox > input:focus ~ span {
            border: 1px solid transparent;
        }
        .btn.btn-primary {
            color: #FFFFFF;
            background-color: #1BC5BD;
            border-color: #1BC5BD;
            font-family: 'NexaBd' !important;
        }
        .btn {
            outline: none !important;
            vertical-align: middle;
            -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
            transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
        }
        .btn {
            display: inline-block;
            font-weight: normal;
            color: #3F4254;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0.65rem 1rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0.42rem;
            -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
        }
        .pl-9, .px-9 {
            padding-left: 2.25rem !important;
        }
        .pr-9, .px-9 {
            padding-right: 2.25rem !important;
        }
        .pb-4, .py-4 {
            padding-bottom: 1rem !important;
        }
        .pt-4, .py-4 {
            padding-top: 1rem !important;
        }
        .mb-3, .my-3 {
            margin-bottom: 0.75rem !important;
        }
        .mt-3, .my-3 {
            margin-top: 0.75rem !important;
        }
        .ml-2, .mx-2 {
            margin-left: 0.5rem !important;
        }

        .mr-2, .mx-2 {
            margin-right: 0.5rem !important;
        }
        .btn.btn-light-primary {
            color: #1BC5BD;
            background-color: #C9F7F5;
            border-color: transparent;
            font-family: 'NexaBd';
        }
        .btn.btn-light-primary:hover {
            color: #FFFFFF;
            background-color: #1BC5BD;
            border-color: #1BC5BD;
        }
        .checkbox > input:checked ~ span:after {
            border-color: #ffffff;
        }
        .checkbox > input:checked ~ span:after {
            display: block;
        }
        .checkbox > span:after {
            width: 5px;
            height: 10px;
        }

        .checkbox > span:after {
            content: "";
            border-color: transparent;
            border-style: solid;
            border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
            -webkit-transform: rotate(
        45deg
        ) /*rtl:ignore*/;
            transform: rotate(
        45deg
        ) /*rtl:ignore*/;
            margin-top: -2px;
        }
    }
    .icon {
        width: 200px;
        margin: 0 auto;
    }

    .login-signin {
        h3 {
            font-size: calc(1.275rem + .3vw);;
        }
        .text-muted {
            color: #b5b5c3!important;
        }
    }

    .login.login-4 .login-form {
        width: 100%;
        max-width: 450px
    }

    .form-control {
        background-color: #f3f6f9;
        height: 50px !important;
        border-color: #f3f6f9;
        width: 90% !important;
        margin: 0 auto;
        border-radius: .675rem !important;
        color: #3f4254;
        -webkit-transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
        transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;
    }

    @media (max-width:575.98px) {
        .login.login-4 .login-form {
            width: 100%;
            max-width: 100%
        }
    }
}

.ingresa{
    font-size: 1.2em;
}

.form-control{}::placeholder{
    color: #a16fac !important;
    font-family: 'NexaLg';
}
